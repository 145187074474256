<template>
    <div color="transparent">
        <!-- 헤더 -->
        <PointHeader />

        <!-- 읽기 -->
        <div class="mt-2 mx-4">
            <!-- 상품 썸네일 -->
            <v-sheet class="pa-6 rounded-15 mb-2">
                <v-img 
                    v-if="product.thumbnail"
                    contain
                    width="70%"
                    class="mx-auto"
                    :src="'/upload/pointmall/thumbnail/'+encodeURI(product.thumbnail)"
                ></v-img>

                <!-- <v-img 
                    contain
                    width="70%"
                    class="mx-auto"
                    src="@/assets/default.png"
                ></v-img> -->

                <!-- 상품명 -->
                <p class="mb-1 text-center mx-4 mt-4 mb-2" style="font-size:12px; font-weight:bold; height:36px; overflow:hidden;">
                    {{product.title}}
                </p>

                <!-- 카테고리, 판매형식, 이벤트타입 -->
                <v-sheet class="d-flex justify-center mx-auto">
                    <font style="font-size:11px;"><v-icon class="pb-2px" size="9" left color="primary">mdi-circle</v-icon>{{product.category}}</font>
                    <font style="font-size:11px;" class="mx-4"><v-icon class="pb-2px" size="9" left color="primary">mdi-circle</v-icon>{{product.sell_type}}</font>
                    <font style="font-size:11px;"><v-icon class="pb-2px" size="9" left color="primary">mdi-circle</v-icon>{{product.event_type}}</font>
                </v-sheet>

                <!-- 기간 -->
                <v-sheet class="d-flex justify-center mt-4 mx-auto">
                    <!-- 기간 있는 이벤트 -->
                    <v-sheet class="d-flex justify-center" v-if="product.status!='무기한'">
                        <p class="my-0 text-center" style="font-size:12px;">
                            <font class="primary--text font-weight-bold mr-2">기간</font>
                            {{new Date(product.start_date).toLocaleDateString()}} ~ {{new Date(product.end_date).toLocaleDateString()}}
                        </p>
                    </v-sheet>

                    <!-- 무기한 -->
                    <v-sheet class="d-flex justify-center" v-if="product.status=='무기한'">
                        <p class="my-0 text-center" style="font-size:12px;">
                            <font class="primary--text font-weight-bold mr-2">기간</font>
                            무기한
                        </p>
                    </v-sheet>
                </v-sheet>
            </v-sheet>

            <!-- 남은 갯수 & 상품 가격 -->
            <v-sheet class="d-flex align-center mb-2 px-12 rounded-10" height="34">
                <!-- 남은 갯수 -->
                <font style="font-size:12px; font-weight:bold;">수량 {{$toComma(remain_amount)}}개</font>

                <v-spacer></v-spacer>

                <!-- 상품 가격 -->
                <font style="font-size:12px; font-weight:bold; color:#F8468D;">{{$toComma(product.price)}}P</font>
                <font class="ml-1" style="font-size:8px; color:#B3B3C2;">(배송비 별도)</font>
            </v-sheet>

            <!-- 구매하기 버튼 -->
            <div class="mb-2">
                <div
                    v-if="(((new Date(product.start_date) < new Date() && new Date(product.end_date) > new Date())) || product.status=='무기한') && product.status!='종료'"
                >
                    <div v-if="!isBuyed">
                        <v-btn class="rounded-10" v-if="!is_switch" depressed color="grey lighten-2" width="100%" height="34">
                            <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">구매 기간이 아닙니다</font>
                        </v-btn>
                        <v-btn class="rounded-10" v-if="is_switch && remain_amount" depressed color="primary" width="100%" height="34" @click="buyOpen()">
                            <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">구매하기</font>
                        </v-btn>
                        <v-btn class="rounded-10" v-if="is_switch && !remain_amount" depressed color="primary" width="100%" height="34" disabled>
                            <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">상품 모두 소진되었습니다</font>
                        </v-btn>
                    </div>

                    <v-btn class="rounded-10" v-if="isBuyed" depressed color="primary" width="100%" height="34" disabled>
                        <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">이미 신청하신 상품입니다</font>
                    </v-btn>
                </div>
                <div v-if="(new Date(product.start_date) > new Date() && new Date(product.end_date) > new Date()) && product.status!='무기한' && product.status!='종료'">
                    <v-btn class="rounded-10" depressed color="primary" width="100%" height="34" disabled>
                        <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">아직 이벤트 기간이 아닙니다</font>
                    </v-btn>
                </div>
                <div v-if="((new Date(product.end_date) < new Date()) && product.status!='무기한')">
                    <v-btn class="rounded-10" depressed color="primary" width="100%" height="34" disabled>
                        <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">종료된 이벤트입니다</font>
                    </v-btn>
                </div>
                <div v-else-if="product.status=='종료'">
                    <v-btn class="rounded-10" depressed color="primary" width="100%" height="34" disabled>
                        <font class="font-weight-bold ml-1 white--text" style="font-size:13px; letter-spacing: 1px !important;">종료된 이벤트입니다</font>
                    </v-btn>
                </div>
            </div>

            <!-- 상품 정보 -->
            <v-sheet class="pa-4 rounded-15 mb-2">
                <!-- 등록일자 & 조회수 -->
                <v-sheet class="d-flex align-end mb-1">
                    <p class="font-weight-bold my-0" style="font-size:13px;">상세정보</p>
                    
                    <v-spacer></v-spacer>

                    <!-- 조회수 -->
                    <p class="my-0 mr-2" style="font-size:10px;">조회수 {{product.view_count}}</p>

                    <!-- 등록일자 -->
                    <p class="my-0" style="font-size:10px; color:#B3B3C2;">{{new Date(product.created).toLocaleString()}}</p>
                </v-sheet>

                <v-divider></v-divider>

                <!-- TipTapReader -->
                <TipTapReader
                    v-if="options.content"
                    :options="options"
                    class="tiptap_style"
                />
            </v-sheet>

            <!-- 안내문구 -->
            <v-sheet class="rounded-15 pa-4 mb-2">
                <!-- TipTapReader -->
                <TipTapReader
                    v-if="guide.options.content"
                    :options="guide.options"
                    class="tiptap_style"
                />
            </v-sheet>

            <!-- 버튼들 -->
            <div class="d-flex justify-center mt-4 mb-2">
                <v-btn
                    width="56"
                    height="34"
                    color="primary"
                    class="rounded-10"
                    style="font-size:12px;"
                    dark
                    depressed
                    @click="$router.push('/pointmall/list')"
                >
                    목록
                </v-btn>

                <!-- 포인트 획득 가이드 -->
                <v-btn
                    width="124"
                    height="34"
                    class="rounded-10 px-0 mx-2"
                    color="#615DFA"
                    dark
                    depressed
                    v-ripple="false"
                    style="font-size:12px; letter-spacing:-0.5px; min-width:0px;"
                    @click="$router.push('/policy/pointguide?type=read')"
                >
                    포인트 획득 가이드
                </v-btn>

                <!-- 상품 협찬하기 -->
                <v-btn
                    width="124"
                    height="34"
                    class="rounded-10 px-0"
                    color="#615DFA"
                    dark
                    depressed
                    v-ripple="false"
                    style="font-size:12px; letter-spacing:-0.5px; min-width:0px;"
                    @click="$router.push('/board/list?board_group=포인트몰&board_name=상품%20협찬하기')"
                >
                    상품 협찬하기
                </v-btn>
            </div>

            <div
                v-if="$store.state.type=='관리자' || ($store.state.type == '운영진' && $store.state.permission_pointmall)"
                class="d-flex justify-center mb-16"
            >
                <!-- 종료 -->
                <v-btn
                    v-if="product.status!='종료'"
                    width="56"
                    height="34"
                    color="#615DFA"
                    class="rounded-10"
                    style="font-size:12px;"
                    dark
                    depressed
                    @click="closeProduct()"
                >
                    종료
                </v-btn>

                <!-- 재시작 -->
                <v-btn
                    v-if="product.status=='종료'"
                    width="56"
                    height="34"
                    color="#615DFA"
                    class="rounded-10 ml-6px"
                    style="font-size:12px;"
                    dark
                    depressed
                    @click="reopenProduct()"
                >
                    재시작
                </v-btn>

                <!-- 복사 -->
                <v-btn
                    width="56"
                    height="34"
                    class="rounded-10 ml-6px"
                    style="font-size:12px;"
                    color="#23D2E2"
                    dark
                    depressed
                    @click="copyProduct()"
                >
                    복사
                </v-btn>

                <!-- 수정 -->
                <v-btn
                    width="56"
                    height="34"
                    class="rounded-10 ml-6px"
                    style="font-size:12px;"
                    color="#1DA1F2"
                    dark
                    depressed
                    @click="updateProduct()"
                >
                    수정
                </v-btn>

                <!-- 삭제 -->
                <v-btn
                    width="56"
                    height="34"
                    class="rounded-10 ml-6px"
                    style="font-size:12px;"
                    color="#F8468D"
                    dark
                    depressed
                    @click="deleteProduct()"
                >
                    삭제
                </v-btn>
            </div>
        </div>

        <!-- 상품 구매하기 -->
        <v-bottom-sheet
            v-model="dialog.buy"
            fullscreen
        >
            <v-sheet height="100vh" class="pa-2" style="overflow:auto;">
                <v-sheet class="pa-2 rounded-15" color="#F8F7FB" style="border:2px solid red; width:100%;">
                    <v-icon style="position:absolute; right:20px; top:16px;" color="red" @click="dialog.buy = false">mdi-close</v-icon>
                    <p
                        class="text-center font-weight-bold mt-2 mb-4"
                        style="font-size:16px;"
                    >
                        상품 구매하기
                    </p>
                    <v-sheet class="pt-4 pb-6 px-4 mt-3 rounded-10 mb-2">
                        <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">상품정보</span>
                        <v-sheet class="d-flex justify-center my-2">
                            <!-- 상품 썸네일 -->
                            <v-sheet width="200" height="215">
                                <v-img
                                    v-if="product.thumbnail"
                                    width="200"
                                    height="215"
                                    contain
                                    class="mx-auto"
                                    :src="'/upload/pointmall/thumbnail/'+encodeURI(product.thumbnail)"
                                ></v-img>
                            </v-sheet>
                            <!-- <v-sheet width="200" height="215">
                                <v-img 
                                    width="200"
                                    height="215"
                                    contain
                                    class="mx-auto"
                                    src="@/assets/default.png"
                                ></v-img>
                            </v-sheet> -->
                        </v-sheet>

                        <div>
                            <!-- 상품명 -->
                            <p
                                class="mb-1 custom-truncate mx-1"
                                style="font-size:11px; font-weight:bold;"
                                :style="product.title.length>20? 'height:32px;':'height:20px'"
                            >
                                {{product.title}}
                            </p>

                            <!-- 카테고리, 판매형식, 이벤트타입 -->
                            <v-sheet class="mt-2 d-flex justify-center">
                                <font style="font-size:11px;"><v-icon class="pb-1" size="9" left color="primary">mdi-circle</v-icon>{{product.category}}</font>
                                <font style="font-size:11px;" class="mx-4"><v-icon class="pb-1" size="9" left color="primary">mdi-circle</v-icon>{{product.sell_type}}</font>
                                <font style="font-size:11px;"><v-icon class="pb-1" size="9" left color="primary">mdi-circle</v-icon>{{product.event_type}}</font>
                            </v-sheet>

                            <!-- 기간 -->
                            <v-sheet class="d-flex justify-center mt-4 mx-auto">
                                <!-- 기간 있는 이벤트 -->
                                <v-sheet class="d-flex justify-center" v-if="product.status!='무기한'">
                                    <p class="my-0 text-center" style="font-size:12px;">
                                        <font class="primary--text font-weight-bold mr-2">기간</font>
                                        {{new Date(product.start_date).toLocaleDateString()}} ~ {{new Date(product.end_date).toLocaleDateString()}}
                                    </p>
                                </v-sheet>

                                <!-- 무기한 -->
                                <v-sheet class="d-flex justify-center" v-if="product.status=='무기한'">
                                    <p class="my-0 text-center" style="font-size:12px;">
                                        <font class="primary--text font-weight-bold mr-2">기간</font>
                                        무기한
                                    </p>
                                </v-sheet>
                            </v-sheet>
                        </div>
                    </v-sheet>

                    <!-- 남은 갯수 & 상품 가격 -->
                    <v-sheet class="d-flex align-center mb-2 px-12 rounded-10" height="34">
                        <!-- 남은 갯수 -->
                        <font style="font-size:12px; font-weight:bold;">수량 {{$toComma(remain_amount)}}개</font>

                        <v-spacer></v-spacer>

                        <!-- 상품 가격 -->
                        <font style="font-size:12px; font-weight:bold; color:#F8468D;">{{$toComma(product.price)}}P</font>
                        <font class="ml-1" style="font-size:8px; color:#B3B3C2;">(배송비 별도)</font>
                    </v-sheet>

                    <!-- 배송지 선택 -->
                    <v-sheet class="py-4 px-6 mt-3 rounded-10">
                        <div
                            class="d-flex"
                        >
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <p style="font-size:15px; font-weight:bold;" class="mb-0 pt-1px">배송지 선택</p>
                            <v-spacer></v-spacer>
                            <v-btn
                                width="48"
                                height="28"
                                class="rounded-10"
                                color="#F8468D"
                                dark
                                depressed
                                @click="dialog.shipping_address = true"
                            >
                                변경
                            </v-btn>
                        </div>
                        <v-sheet class="py-2 px-2">
                            <v-sheet v-if="Object.keys(selected_shipping_address)?.length" class="d-flex align-center">
                                <div>
                                    <p class="mb-0">
                                        <font class="font-weight-bold">{{selected_shipping_address.receiver}}</font>
                                        <font class="mx-1"> | </font>
                                        <font class="font-weight-bold">{{selected_shipping_address.phone}}</font>
                                    </p>
                                    <p class="mb-0">{{selected_shipping_address.address}}</p>
                                </div>
                                <v-spacer></v-spacer>
                            </v-sheet>
                            <v-sheet v-if="!Object.keys(selected_shipping_address)?.length">
                                <p class="text-center mb-4 font-weight-bold">설정된 기본 배송지가 없습니다.</p>
                            </v-sheet>
                        </v-sheet>
                    </v-sheet>

                    <!-- 배송비 결제 방식 -->
                    <v-sheet class="py-4 px-6 mt-3 rounded-10">
                        <div class="d-flex align-center">
                            <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span style="font-size:15px; font-weight:bold; margin-right:110px;">배송비 결제 방식</span>
                        </div>
                        <v-select
                            :items="select_list.delivery_pay_type"
                            v-model="delivery_pay_type"
                            outlined
                            dense
                            class="rounded-10 mx-4 mt-3"
                            hide-details
                            placeholder="배송비 결제 방식 선택"
                        >
                            <template v-slot:append>
                                <v-icon color="#424361">mdi-chevron-down</v-icon>
                            </template>
                        </v-select>
                    </v-sheet>

                    <!-- 요청사항 -->
                    <v-sheet class="py-4 px-6 mt-3 rounded-10">
                        <div class="d-flex align-center">
                            <v-icon size="8" color="#ADAFCA" class="pb-2px mr-3">mdi-circle</v-icon>
                            <span style="font-size:15px; font-weight:bold; margin-right:110px;">요청사항</span>
                        </div>
                        <v-text-field
                            v-model="request_memo"
                            outlined
                            dense
                            class="rounded-10 mx-4 mt-3"
                            hide-details
                            placeholder="요청사항 입력"
                        ></v-text-field>
                    </v-sheet>

                    <!-- 포인트 -->
                    <v-sheet class="py-4 px-6 mt-3 rounded-10">
                        <v-icon size="8" color="#F8468D" class="pb-2px mr-3">mdi-circle</v-icon>
                        <span style="font-size:15px; font-weight:bold;">정산</span>

                        <div
                            class="d-flex align-center justify-space-between mt-2 mx-4"
                        >
                            <div class="d-flex justify-center">
                                <p style="font-size:12px; font-weight:bold; text-align:end; line-height:20px; margin-bottom:0px;">
                                    보유포인트 <span style="color:#23D2E2">{{$toComma(present_point)}}P</span><br/>
                                    
                                    <v-icon color="#F8468D" size="12" class="mr-2">mdi-minus-circle-outline</v-icon><font style="color:#F8468D">결제포인트</font> <font style="color:#F8468D" class="ml-2">{{$toComma(pay_point)}}P</font><br/>
                                    잔여포인트 <font style="color:#23D2E2">{{$toComma(remain_point)}}P</font>
                                </p>
                            </div>
                            <v-btn
                                width="64"
                                height="64"
                                class="rounded-10"
                                style="font-size:11px; font-weight:bold;"
                                dark
                                depressed
                                :disabled="remain_point<0"
                                color="#615DFA"
                                @click="buySubmit()"
                            >
                                구매하기
                            </v-btn>
                        </div>

                        <p v-if="remain_point<0" class="text-right red--text text-caption mb-1 mr-1">결제할 포인트가 부족합니다.</p>
                    </v-sheet>
                </v-sheet>
            </v-sheet>
        </v-bottom-sheet>

        <!-- 배송지 선택 -->
        <v-dialog v-model="dialog.shipping_address" content-class="rounded-10">
            <v-sheet class="py-4 px-2">
                <p class="text-center font-weight-bold pb-3 my-0" style="font-size:16px;">배송지 선택</p>
                <v-divider></v-divider>
                <v-sheet>
                    <v-data-table
                        :headers="headers"
                        :items="shipping_address_list"
                        mobile-breakpoint="0"
                    >
                        <template v-slot:item="{ item }">
                            <tr @click="select_shipping_address(item)" style="cursor:pointer">
                                <td style="width:80px;"><font class="d-block text-truncate" style="width:80px;">{{item.receiver}}</font></td>
                                <td style="width:90px;"><font class="d-block text-truncate" style="width920px;">{{item.phone}}</font></td>
                                <td style="width:100%;"><font class="d-block text-truncate" style="width:380px;">{{item.address}}</font></td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-sheet>
            </v-sheet>
        </v-dialog>
    </div>
</template>
<script>
import PointHeader from '../components/header'
import TipTapReader from "@/components/tiptap/Reader"

export default {
    components: {
        PointHeader,
        TipTapReader
    },

    data: () => ({
        headers: [
            {text: '수령인'},
            {text: '전화번호'},
            {text: '주소'},
        ],

        //TipTap
        options: {
            content: '',
            readonly: true,
            editable: true,
            supportImage: true,
            supportVideo: true,
        },

        //안내문구
        guide: {
            options: {
                content: '',
                readonly: true,
                editable: true,
                supportImage: true,
                supportVideo: true,
            }
        },

        product: {},

        dialog: {
            buy: false,
            shipping_address: false
        },

        select_list: {
            delivery_pay_type: [
                '선 결제(포인트)',
                '착불',
            ]
        },

        selected_shipping_address: {},
        shipping_address_list: [],
        delivery_pay_type: '',
        present_point: 0,
        sold_amount: 0,
        isBuyed: 0,

        request_memo: "",
        is_switch: 1,
    }),

    mounted(){
        // 안내문구 불러오기
        this.loadGuide()

        // 상품 정보 불러오기
        this.load()

        // 조회수 증가
        this.view_count_increase()

        // 판매된 갯수
        this.$http.post('/api/pointmall/order/select/sold/count', {
            params: {
                product_id: this.$route.query.id
            }
        }).then((res) => {
            this.sold_amount = res.data[0].sold_amount
        })

        // 구매한 상품인지 확인
        this.$http.post('/api/pointmall/order/select/check/buy', {
            params: {
                product_id: this.$route.query.id,
                user_id: this.$store.state.user_id
            }
        }).then((res) => {
            this.isBuyed = res.data[0].count
        })

        // 전체 활성/비활성
        this.$http.post('/api/admin_function/select/pointmall_switch')
        .then((res) => {
            // console.log(res)
            if(res.data[0].pointmall_switch == 0){
                this.is_switch = 0
            }else{
                this.is_switch = 1
            }
        })
    },

    watch: {
        delivery_pay_type(){
            console.log("HI")
            if(this.delivery_pay_type=='선 결제(포인트)'){
                this.$http.post('/api/user/select/specific', {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data[0].type=='사용자' && res.data[0].grade < 4){
                        alert("배송비 선 결제는 최소 회원레벨 4 (Star 1)부터 가능합니다.")
                        this.delivery_pay_type = '착불'
                    }
                })
            }
        }
    },

    computed: {
        pay_point(){
            return (this.delivery_pay_type=='선 결제(포인트)'? this.product.delivery_price:0) + this.product.price
        },

        remain_point(){
            return this.present_point - this.pay_point
        },

        remain_amount() {
            return Math.max(0, this.product.amount - this.sold_amount)
        }
    },

    methods: {
        // 안내문구 불러오기
        loadGuide(){
            this.$http.post('/api/admin/pointmall/guide/import')
            .then(res => {
                this.guide.options.content = res.data[0].content
            })
        },

        // 상품 정보 불러오기
        load(){
            this.$http.post('/api/pointmall/select/read', {
                params: {
                    id: this.$route.query.id
                }
            }).then((res) => {
                this.product = res.data[0]
                this.options.content = res.data[0].content
            })
        },

        // 조회수 증가
        view_count_increase(){
            this.$http.post('/api/pointmall/update/view_count/increase', {
                params: {
                    id: this.$route.query.id
                }
            })
        },

        // 상품 종료하기
        closeProduct(){
            if(confirm("해당 상품을 정말 종료하시겠습니까?")){
                this.$http.post('/api/pointmall/update/close', {
                    params: {
                        id: this.$route.query.id
                    }
                }).then(() => {
                    alert("상품이 종료되었습니다.")
                    this.load()
                })
            }
        },

        // 상품 재시작하기
        reopenProduct(){
            if(confirm("해당 상품을 정말 재시작하시겠습니까?\n재시작시 이벤트 기간은 무기한으로 설정되며,\n원치않을 시 수정하기로 기간을 설정하시기 바랍니다.")){
                this.$http.post('/api/pointmall/update/reopen', {
                    params: {
                        id: this.$route.query.id
                    }
                }).then(() => {
                    alert("상품이 재시작되었습니다.")
                    this.load()
                })
            }
        },

        // 상품 복사하기
        copyProduct(){
            if(confirm("해당 상품을 정말 복사하시겠습니까?")){
                this.$http.post('/api/pointmall/insert', {
                    params: {
                        thumbnail : this.product.thumbnail,
                        title : this.product.title + ' 복사본',
                        content : this.options.content,
                        sell_type : this.product.sell_type,
                        price : this.product.price,
                        delivery_price : this.product.delivery_price,
                        category : this.product.category,
                        start_date : new Date(this.product.start_date).toISOString().slice(0, -5),
                        end_date : new Date(this.product.end_date).toISOString().slice(0, -5),
                        event_type : this.product.event_type,
                        amount : this.product.amount,
                        status : this.product.status
                    }
                }).then(() => {
                    alert("상품이 복사되었습니다.")
                    this.$router.push('/pointmall/list')
                })
            }
        },

        // 상품 수정하기
        updateProduct(){
            this.$router.push('/pointmall/update?id='+this.$route.query.id)
        },

        // 상품 삭제하기
        deleteProduct(){
            if(confirm("해당 상품을 정말 삭제하시겠습니까?")){
                this.$http.post('/api/pointmall/delete', {
                    params: {
                        id: this.$route.query.id
                    }
                }).then((res) => {
                    alert('해당 상품이 삭제되었습니다.')
                    this.$router.push('/pointmall/list')
                })
            }
        },

        // 구매하기 (열기)
        buyOpen(){
            // 임시 비활성화
            // alert("점검중입니다.")
            // return
            
            if(!this.$store.state.is_logined){
                alert("포인트몰 상품 구매는 로그인 이후 가능합니다.")
            }else if(this.$store.state.type=='밴 유저'){
                alert('밴 유저는 밴 기간동안 포인트몰 상품 구매가 제한됩니다.')
            }else{
                this.$http.post('/api/user/select/specific', {
                    params: {
                        user_id: this.$store.state.user_id
                    }
                }).then((res) => {
                    if(res.data[0].type=='사용자' && res.data[0].grade < 2){
                        alert("1레벨 이하는 포인트몰을 이용하실 수 없습니다.")
                    }else{
                        // 특정 사용자의 전체 배송지 불러오기
                        this.$http.post('/api/shipping/select', {
                            params: {
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            this.shipping_address_list = res.data
                        })

                        // 사용자의 기본 배송지 가져오기
                        this.$http.post('/api/shipping/select/default', {
                            params: {
                                user_id: this.$store.state.user_id
                            }
                        }).then((res) => {
                            if(res.data.length){
                                this.selected_shipping_address = res.data[0]
                            }
                        })

                        // 사용자의 현재 포인트 가져오기
                        this.$http.post('/api/user/select/specific', {
                            params: {
                                user_id : this.$store.state.user_id
                            }
                        }).then((res) => {
                            this.present_point = res.data[0].point
                        })

                        // 구매하기 닫기
                        this.dialog.buy = true
                    }
                })
            }
        },

        // 다른 배송지 선택
        select_shipping_address(item){
            this.selected_shipping_address = item
            this.dialog.shipping_address = false
        },

        // 구매 신청하기
        buySubmit(){
            if(this.delivery_pay_type == ''){
                alert('배송비 결제방식을 선택해주세요')
            }else if(!Object.keys(this.selected_shipping_address).length){
                alert('배송지를 선택해주세요')
            }else if(this.remain_point < 0){
                alert('결제할 포인트가 부족합니다')
            }else{
                this.$http.post('/api/pointmall/order/insert', {
                    params: {
                        user_id: this.$store.state.user_id,
                        product_id: this.$route.query.id,
                        receiver: this.selected_shipping_address.receiver,
                        phone: this.selected_shipping_address.phone,
                        address: this.selected_shipping_address.address,
                        delivery_pay_type: this.delivery_pay_type,
                        request_memo: this.request_memo,
                        status: "신청완료"
                    }
                }).then((res) => {
                    if(!res.data.affectedRows){
                        alert("신청중 오류가 발생하였습니다.")
                    }else{
                        // 포인트 차감은 관리자 페이지로 이동
                        this.$http.post('/api/user/update/point/decrease', {
                            params: {
                                amount: this.pay_point,
                                user_id: this.$store.state.user_id
                            }
                        })

                        // 포인트 내역 기록
                        this.$http.post('/api/admin/pointmall/order/accept/point_list/add', {
                            params: {
                                user_id: this.$store.state.user_id,
                                title: this.product.title,
                                menu: '포인트몰',
                                activity: '상품구매',
                                pay_point: -this.pay_point,
                            }
                        }).then((res) => {
                            console.log(res)
                        })

                        // DB 알림
                        this.$dbAlarm(
                            "포인트몰 - 구매",
                            this.$store.state.nickname,
                            this.$store.state.user_id
                        )

                        alert("포인트몰 상품 구매신청이 정상적으로 이뤄졌습니다.\n확인 후 쪽지 또는 연락처로 연락드리겠습니다.")
                        this.dialog.buy = false
                        this.$router.go(this.$router.currentRoute)
                    }
                })
            }
        }
    }
}
</script>
<style scoped>
/* Custom Truncate */
.custom-truncate{
    display: -webkit-box;
    max-width: 340px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
</style>
